<template>
  <div class="module-single_picture">
    <h5 class="m_title txt-bold txt-theme" v-if="title">{{title}}</h5>
    <div class="picture_outer">
      <img v-if="src" :src="src" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleModuleSinglePicture',
  props: ['title', 'src'],
  data() {
    return {
    };
  },
};
</script>
