<template>
  <div class="story_article">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"/>
    <Article
      :articleData="articleData"/>
    <router-link
    :class="[getLanguage === 'en-US' ? 'btnEn' : '']"
    to="/story/1" class="btn" v-show="$route.name === 'StoryArticle'">
      {{$t('backToStory')}}
    </router-link>
  </div>
</template>

<script>
import '@/assets/scss/story.scss';
import BannerKv from '@/components/BannerKv.vue';
import Article from '@/components/Article.vue';
import { getStoryContent, getStoryContentPreview } from '@/lib/public';
import { mapGetters } from 'vuex';

import pic01 from '@/assets/img/index/story_kv.jpg';

export default {
  name: 'StoryArticle',
  components: {
    BannerKv,
    Article,
  },
  props: ['articleId'],
  data() {
    return {
      BannerKvData: {
        titleEn: 'NUWA BABY',
        titleZh: this.$t('nuwaStories'),
        backgroundPic: pic01,
      },
      articleData: {
        childArticle: null,
        data: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getLanguage',
    ]),
  },
  methods: {
    getStoryContent() {
      getStoryContent(this.articleId, (res) => {
        if (res.status === 200) {
          this.articleData = {
            data: [
              {
                type: 1,
                title: res.data.data.title,
              },
              {
                type: 10,
                title: null,
                text: res.data.data.content,
              },
            ],
          };
        }
      });
    },
    getStoryContentPreview() {
      getStoryContentPreview(this.articleId, (res) => {
        if (res.status === 200) {
          this.articleData = {
            data: [
              {
                type: 1,
                title: res.data.data.title,
              },
              {
                type: 10,
                title: null,
                text: res.data.data.content,
              },
            ],
          };
        }
      });
    },
  },
  mounted() {
    console.log(this.$route.name);
    if (this.$route.name === 'StoryArticle') {
      this.getStoryContent();
    } else {
      this.getStoryContentPreview();
    }
  },
};
</script>
