<template>
  <section id="banner_kv"
    :style="{backgroundImage: `url(${bg})`}"
    :class="{noBg : ! backgroundPic}">
    <div class="text">
      <h4 style="margin-bottom:10px;">
        {{titleZh}}
      </h4>
      <h6 class="eng">
        {{titleEn}}
        <img class="deco_flower" src="@/assets/img/deco_flower_white.svg" alt="">
      </h6>
    </div>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px"
      viewBox="0 0 1920 157" enable-background="new 0 0 1920 157"
      xml:space="preserve">
      <path
        :fill="bgcolor ? bgcolor : '#f8f8f8'"
        d="M0,6c0,0,354,151,960,151c652,0,960-151,960-151v151H0V6z"/>
      <image overflow="visible" width="1920" height="157"
        xlink:href="@/assets/img/banner_bg.png" >
      </image>
    </svg>
  </section>
</template>

<script>
export default {
  name: 'BannerKv',
  props: ['titleEn', 'titleZh', 'backgroundPic', 'backgroundPicRwd', 'bgcolor'],
  data() {
    return {
      bg: this.backgroundPic,
    };
  },
  mounted() {
    if (window.innerWidth <= 768 && this.backgroundPicRwd) {
      this.bg = this.backgroundPicRwd;
    }
  },
};
</script>
