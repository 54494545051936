<template>
  <div class="module-full_picture">
    <div class="picture_outer">
      <img src="@/assets/img/NUWA_News/8.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleModuleFullPicture',
  props: [],
  data() {
    return {
    };
  },
};
</script>
