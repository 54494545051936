<template>
  <div class="module-full_video">
    <div class="video_outer">
      <!-- <video controls>
        <source src="@/assets/img/kv-video2.mp4" type="video/mp4">
      </video> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleModuleFullVideo',
  props: [],
  data() {
    return {
    };
  },
};
</script>
