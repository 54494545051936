<template>
  <section id="article_info">
    <div class="area w1300">

      <ShareBtnGroup/>
      <div
        v-for="(item, key) in articleData.data"
        :key="key+'title'">
        <!-- 文章標題 -->
        <ArticleModuleTitle
          v-if="item.type === 1"
          :title="item.title"
          :tag="item.tag"
          :date="item.date"/>
      </div>

      <!-- 子文章 -->
      <div class="sec_fliter" v-if="articleData.childArticle">
        <router-link
          v-for="(item, key) in articleData.childArticle"
          :key="key"
          :to="item.path"
          active-class="active"
          exact>
          {{item.linkName}}
        </router-link>
      </div>

      <div
        v-for="(item, key) in articleData.data"
        :key="key">
        <!-- 單張圖片 -->
        <ArticleModuleSinglePicture
          v-if="item.type === 2"
          :title="item.title"
          :src="item.src"/>

        <!-- 滿版圖片 -->
        <ArticleModuleFullPicture
          v-if="item.type === 3"/>

        <!-- 文章區塊 -->
        <ArticleModuleSingleText
          v-if="item.type === 4"
          :title="item.title"
          :text="item.text"/>

        <!-- 左圖右文 -->
        <div class="module-picture_text" v-if="item.type === 5">
          <h5 class="m_title txt-bold txt-theme">左圖右文</h5>
          <div class="box">
            <div class="picture_outer">
              <img src="@/assets/img/about/more_1.jpg" alt="">
            </div>
            <div class="text_outer">
              <p class="small">
                希望能夠幫助難孕夫妻早日成家，早日帶個健康寶寶回家。
                也因此黃醫師決定從婦產科轉為不孕症專科醫師，目的就是想好好為求孕夫妻把關每一個環節，找出原因並解決問題，幫助他們盡快懷孕！
                <br><br>
                黃醫師因為希望求孕夫妻可以對於療程更加瞭解、放心，於是利用下診後的時間經營個人部落格，嘗試用簡白易懂的文字，搭配圖片說明試管療程，是不是超認真、用心的呢？
                <br><br>
                快來看影片更加認識黃曉莉醫師吧！
              </p>
            </div>
          </div>
        </div>

        <!-- 右圖左文 -->
        <div class="module-text_picture" v-if="item.type === 6">
          <h5 class="m_title txt-bold txt-theme" v-if="item.title">{{item.title}}</h5>
          <div class="box">
            <div class="text_outer" v-if="item.text">
              <p class="small" v-html="item.text">
              </p>
            </div>
            <div class="picture_outer">
              <img
                v-if="item.src"
                :src="item.src" alt="">
            </div>
          </div>
        </div>

        <!-- 右圖左圖 -->
        <div class="module-picture_picture"
          v-if="item.type === 7">
          <h5 class="m_title txt-bold txt-theme" v-if="item.title">{{item.title}}</h5>
          <div class="box">
            <div class="picture_outer" v-if="item.src[0]">
              <img :src="item.src[0]" alt="">
            </div>
            <div class="picture_outer" v-if="item.src[1]">
              <img :src="item.src[1]" alt="">
            </div>
          </div>
        </div>

        <!-- 單部影片 -->
        <ArticleModuleSingleVideo
          v-if="item.type === 8"
          :src="item.src"/>

        <!-- 滿版影片 -->
        <ArticleModuleFullVideo v-if="item.type === 9"/>

        <!-- html -->
        <div
          class="module-html"
          v-if="item.type === 10"
          v-html="item.text">
        </div>

      </div>

      <!-- 文件下載 -->
      <div id="files">
        <div class="container w1000">
          <div class="item" v-for="(file, i) in files" :key="i">
            <a :href="file.file" download="test.txt" class="download small" rel="noopener noreferrer">
              <img src="@/assets/img/Entry_Permit_Application/icon_1.svg" alt="">
              {{file.name}}
            </a>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import ArticleModuleTitle from '@/components/ArticleModuleTitle.vue';
import ArticleModuleSinglePicture from '@/components/ArticleModuleSinglePicture.vue';
import ArticleModuleFullPicture from '@/components/ArticleModuleFullPicture.vue';
import ArticleModuleSingleText from '@/components/ArticleModuleSingleText.vue';
import ArticleModuleSingleVideo from '@/components/ArticleModuleSingleVideo.vue';
import ArticleModuleFullVideo from '@/components/ArticleModuleFullVideo.vue';
import ShareBtnGroup from '@/components/ShareBtnGroup.vue';

export default {
  name: 'Article',
  components: {
    ArticleModuleTitle,
    ArticleModuleSinglePicture,
    ArticleModuleFullPicture,
    ArticleModuleSingleText,
    ArticleModuleSingleVideo,
    ArticleModuleFullVideo,
    ShareBtnGroup,
  },
  props: ['articleData', 'files'],
  data() {
    return {
    };
  },
  methods: {
    // download(e) {
    //   e.preventDefault();
    //   const name = e.download;
    //   const a = document.createElement('a');
    //   a.style.display = 'none';
    //   a.href = this.files[0].file;
    //   // the filename you want
    //   a.download = name;
    //   document.body.appendChild(a);
    //   a.click();
    // },
  },
  watch: {
    // files(val) {
    //   const myNewFile = new URL(val[0].file);
    //   console.log(val[0].file.split('.')[2]);
    //   console.log(myNewFile);
    //   const a = document.createElement('a');
    //   a.style.display = 'none';
    //   a.href = myNewFile;
    //   // the filename you want
    //   a.download = `${val[0].name}.${val[0].file.split('.')[2]}`;
    //   document.body.appendChild(a);
    //   a.click();
    //   window.URL.revokeObjectURL(myNewFile);
    // },
  },
  mounted() {
  },
};
</script>
