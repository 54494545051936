<template>
  <div class="module-single_text">
    <h5 class="m_title txt-bold txt-theme" v-if="title">{{title}}</h5>
    <div class="text_outer">
      <p class="small" v-html="text">
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleModuleSingleText',
  props: ['title', 'text'],
  data() {
    return {
    };
  },
};
</script>
