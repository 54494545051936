<template>
  <div class="module-title">
    <h5 class="h5-25 txt-bold">
      {{title}}
    </h5>
    <p class="date" v-if="tag || date">
      <span class="tag txt-white small" v-if="tag">{{tag}}</span>
      <span v-if="date">
        {{date}}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ArticleModuleTitle',
  props: ['title', 'tag', 'date'],
  data() {
    return {
    };
  },
};
</script>
