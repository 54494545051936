<template>
  <div id="share-btn-group">
    <div class="share-btn-group-wrapper">
      <p class="small">{{$t('share')}}</p>
      <div v-for="(icon, k) in snslink" :key="`share_${k}`"
        :class="`share-btn-icon_${icon.name}`"
        @click="icon.action"
        ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShareBtnGroup',
  data() {
    return {
    };
  },
  computed: {
    snslink() {
      return [
        {
          name: 'fb',
          action: this.share,
        },
        {
          name: 'copy',
          action: this.copyURL,
        },
      ];
    },
  },
  methods: {
    copyURL() {
      const target = document.createElement('input');
      const domain = window.location.origin;
      target.value = `${domain}${this.$route.path}`;
      document.body.appendChild(target);
      target.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.$customSWAL({
            text: this.$t('copyComplete'),
          });
        }
      } catch (err) {
        console.log('NO');
      }

      target.className = 'target';
      target.style.display = 'none';
    },
    share() {
      const domain = window.location.origin;
      const fullURL = `${domain}${this.$route.path}`;
      window.open(`https://www.facebook.com/sharer.php?u=${fullURL}`, '_blank');
    },
  },
};
</script>
